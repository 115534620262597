@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-Regular.4a370d46.eot");
  src: local(Ubuntu Regular), local(Ubuntu-Regular), url("Ubuntu-Regular.4a370d46.eot#iefix") format("embedded-opentype"), url("Ubuntu-Regular.bcf2adac.woff") format("woff"), url("Ubuntu-Regular.f225c9c8.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-Light.e149e115.eot");
  src: local(Ubuntu Light), local(Ubuntu-Light), url("Ubuntu-Light.e149e115.eot#iefix") format("embedded-opentype"), url("Ubuntu-Light.57745c97.woff") format("woff"), url("Ubuntu-Light.7bb98302.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-LightItalic.02850e2d.eot");
  src: local(Ubuntu Light Italic), local(Ubuntu-LightItalic), url("Ubuntu-LightItalic.02850e2d.eot#iefix") format("embedded-opentype"), url("Ubuntu-LightItalic.efff601b.woff") format("woff"), url("Ubuntu-LightItalic.1c2ae274.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-Medium.8b280f43.eot");
  src: local(Ubuntu Medium), local(Ubuntu-Medium), url("Ubuntu-Medium.8b280f43.eot#iefix") format("embedded-opentype"), url("Ubuntu-Medium.68e1710a.woff") format("woff"), url("Ubuntu-Medium.11e6f6d3.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-Italic.66d8fcaa.eot");
  src: local(Ubuntu Italic), local(Ubuntu-Italic), url("Ubuntu-Italic.66d8fcaa.eot#iefix") format("embedded-opentype"), url("Ubuntu-Italic.8a5bf9d6.woff") format("woff"), url("Ubuntu-Italic.8697ceaf.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-Bold.a46b5ac3.eot");
  src: local(Ubuntu Bold), local(Ubuntu-Bold), url("Ubuntu-Bold.a46b5ac3.eot#iefix") format("embedded-opentype"), url("Ubuntu-Bold.4cc81433.woff") format("woff"), url("Ubuntu-Bold.b3b85830.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-BoldItalic.f039dcdc.eot");
  src: local(Ubuntu Bold Italic), local(Ubuntu-BoldItalic), url("Ubuntu-BoldItalic.f039dcdc.eot#iefix") format("embedded-opentype"), url("Ubuntu-BoldItalic.0b822774.woff") format("woff"), url("Ubuntu-BoldItalic.c389a436.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Ubuntu;
  src: url("Ubuntu-MediumItalic.d4a1735a.eot");
  src: local(Ubuntu Medium Italic), local(Ubuntu-MediumItalic), url("Ubuntu-MediumItalic.d4a1735a.eot#iefix") format("embedded-opentype"), url("Ubuntu-MediumItalic.915ddc31.woff") format("woff"), url("Ubuntu-MediumItalic.b0f1c27c.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
/*# sourceMappingURL=404.dab058db.css.map */
